<template>
    <div>
        <CardOpportunityComponent class="card" v-for="item in opportunities" :key="item.idImmobile" :item="item" :solded="true" :isBroker="true"/>
    </div>
</template>

<script>
import { methods, mounted } from 'vue-echarts'

import CardOpportunityComponent from '../site/components/opportunity/CardOpportunity.vue'
import OpportunityService from '../site/services/OpportunityService'

export default {
    components: {
       CardOpportunityComponent 
    },

    data(){
        return {
            opportunities: []
        }
    },

    methods: {
        async getList(){
           this.opportunities = (await OpportunityService.getImmobilesBroker()).data.result
        }
    },

    mounted(){
        this.getList()
    }
}
</script>
<style>
.d-card-group {
  border-radius: 12px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
}
.card-opportunity .content {
    margin-left: 0 !important;
}
</style>